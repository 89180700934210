import { reactive, shallowReactive, ref, watch } from 'vue';
import qPon from '@/api/Coupon.js';
import { ElMessage } from 'element-plus';
import useExchange from './useExchange';
import { isEmpty } from '@/utils/index';
import { useRouter, useRoute } from 'vue-router';
import _ from 'lodash';

export default (options) => {
  const loading = ref(null);

  const router = useRouter();
  const route = useRoute();
  const form = reactive({
    dataType: options.dataType || 'all',
    type: options.type || 'mine',
  });
  const rows = shallowReactive([]);
  const dataType = reactive([]);
  const fetcher = () => {
    loading.value = true;
    qPon
      .myCoupon({ ...form })
      .then((res) => {
        dataType.length = 0;
        if (res.code === 1) {
          rows.splice(0, rows.length, ...res.data.rows);
          if (res.data.dataType) {
            dataType.push(...res.data.dataType);
          }
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const syncQuery = () => {
    router.push({
      query: { ..._.omitBy({ ...form }, isEmpty) },
    });
  };
  const updateForm = (params) => {
    Object.assign(form, { ...params });
  };

  watch(
    () => _.cloneDeep(form),
    (val, old) => {
      if (_.isEqual(val, old)) return;

      fetcher();
      syncQuery();
    },
    { immediate: false, deep: true },
  );

  return { rows, dataType, fetcher, form, loading };
};
