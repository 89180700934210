import { reactive, ref, shallowReactive } from 'vue';
import Payment from '@/api/Payment';
export default () => {
  const loading = ref(false);
  const rows = shallowReactive([]);
  const reset = () => {
    rows.length = 0;
  };
  const fetcher = (id) => {
    loading.value = true;
    return Payment.subs({ subId: id })
      .then((res) => {
        if (res.code === 1) {
          if (res.data.rows.length) {
            rows.splice(0, rows.length, ...res.data.rows);
          }
        }
        return res;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return { rows, fetcher, loading, reset };
};
