import { reactive, ref } from 'vue';
import Payment from '@/api/Payment';

export default () => {
  const loading = ref(false);
  const rows = reactive([]);
  const desc = ref();
  const selectDesc = ref();
  const fetcher = (id) => {
    loading.value = true;
    return Payment.types()
      .then((res) => {
        if (res.code === 1) {
          rows.splice(0, rows.length, ...res.data.rows);
          desc.value = res.data.desc;
          selectDesc.value = res.data.selectDesc;
        }
        return res;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const reset = () => {
    rows.length = 0;
  };

  return { rows, fetcher, loading, reset, desc, selectDesc };
};
