<template>
  <router-view :key="$route.path"></router-view>
</template>
<script setup>
import { provide, watch } from 'vue';
import useShop from '@/modules/shop/useShop';
import useTypes from '@/modules/shop/useTypes';
import usePlans from '@/modules/shop/usePlans';
import useInvoice from '@/modules/invoice/useInvoice';
import useSubs from '@/modules/shop/useSubs';
import { useRoute, useRouter } from 'vue-router';
import useCoupon from '@/modules/coupon/useCoupon';
import _ from 'lodash';
import { ElMessage } from 'element-plus';
const route = useRoute();
const router = useRouter();

const invoice = useInvoice();
provide('invoiceData', invoice);

const typesData = useTypes();
provide('typesData', typesData);
typesData.fetcher();

const plansData = usePlans();
provide('plansData', plansData);

const subsData = useSubs();
provide('subsData', subsData);

const couponData = useCoupon({
  type: 'pay',
  dataType: 'price',
});
couponData.fetcher();
provide('couponData', couponData);

const shopData = useShop({
  coupons: couponData.rows,
  plans: plansData,
  subs: subsData,
  invoice,
});
provide('shopData', shopData);

watch(
  () => route.query,
  (query, old) => {
    if (_.isEqual(query, old)) return;

    let id;
    if (query.is_sub == 1 && query.subId && query.subId != old?.subId) {
      id = query.subId;
    } else if (query.is_sub == 0 || !query.is_sub) {
      id = query.typeId;
    }

    plansData.reset();
    if (id) {
      plansData.fetcher(id).then((res) => {
        if (res.code !== 1) {
          ElMessage('方案不存在');
          router.push({ name: 'Shop' });
        }
      });
    }
  },
  { deep: true, immediate: true },
);

if (!route.query.typeId) {
  router.push({ name: 'Shop.Home' });
}
</script>
